import React from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../Shared/Footer";
import Header from "../../Shared/Header";
import ImageGrid from "../../Shared/ImageGrid";

export default function Work() {
  const { t } = useTranslation();
  const companyImages = [
    {
      name: "AZ",
      image: require("../../../Assets/companies/az.png"),
    },
    {
      name: "Capgemini",
      image: require("../../../Assets/companies/capgemini.svg").default,
    },
    {
      name: "Zorg van de Zaak",
      image: require("../../../Assets/companies/zorgvdzaak.svg").default,
    },
    {
      name: "Mediahuis",
      image: require("../../../Assets/companies/mediahuis.svg").default,
    },
    {
      name: "RVA",
      image: require("../../../Assets/companies/rva.svg").default,
    },
    {
      name: "Politie",
      image: require("../../../Assets/companies/politie.svg").default,
    },
  ];
  return (
    <>
      <Header />
      <section>
        <div className="container">
          <div className="content-wrapper">
            <div className="content">
              <h2>{t("work.holder.title")}</h2>
              <p>{t("work.holder.description.0")}</p>
              <p>{t("work.holder.description.1")}</p>
              <ImageGrid images={companyImages} showName={false} columns={3} />
              <p></p>
              <p>{t("work.holder.description.2")}</p>
              <p>{t("work.holder.description.3")}</p>
              <p>{t("work.holder.description.4")}</p>
            </div>
          </div>
          <div className="content-wrapper">
            <div className="content-logo hide-mobile">
              <img
                src={require("../../../Assets/logo-v2.png")}
                alt="Tecspert logo"
              />
            </div>
            <div className="content">
              <h2>{t("work.tecspert.title")}</h2>
              <p>{t("work.tecspert.description.0")}</p>
              <p>{t("work.tecspert.description.1")}</p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
