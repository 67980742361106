import React from "react";
import { brands, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import Footer from "../../Shared/Footer";
import Header from "../../Shared/Header";
import ImageGrid from "../../Shared/ImageGrid";

export default function Home() {
  const { t } = useTranslation();

  return (
    <>
      <Header isBannerShown={true}>
        <div className="container">
          <div className="banner">
            <div className="profile-info">
              <h1>Roy Willemse</h1>
              <h2>Freelance Developer & Test Automation</h2>
              <div className="social-links">
                <a
                  href="https://www.linkedin.com/in/roy-willemse/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={brands("linkedin-in")} />
                </a>
                <a href="mailto:roy@tecspert.nl">
                  <FontAwesomeIcon icon={solid("envelope")} />
                </a>
              </div>
            </div>
            <img
              className="profile-img"
              src={require("../../../Assets/roy_willemse.png")}
              alt="Roy Willemse"
            />
          </div>
        </div>
      </Header>
      <section>
        <div className="container">
          <div className="content-wrapper left">
            <div className="content">
              <h2>{t("home.intro.title")}</h2>
              <p>{t("home.intro.description")}</p>
            </div>
          </div>
          <div className="content-wrapper">
            <div className="content-half">
              <h3>{t("home.development.title")}</h3>
              <p>
                {t("home.development.description.0")}
                <br />
                {t("home.development.description.1")}
              </p>
              <ImageGrid
                images={[
                  {
                    name: "React",
                    image: require("../../../Assets/Icons/react.svg").default,
                  },
                  {
                    name: "VueJS",
                    image: require("../../../Assets/Icons/vuejs.svg").default,
                  },
                  {
                    name: "Typescript",
                    image: require("../../../Assets/Icons/typescript.svg")
                      .default,
                  },
                  {
                    name: "NodeJS",
                    image: require("../../../Assets/Icons/nodejs.svg").default,
                  },
                  {
                    name: "GraphQL",
                    image: require("../../../Assets/Icons/graphql.svg").default,
                  },
                  {
                    name: "HTML",
                    image: require("../../../Assets/Icons/html.svg").default,
                  },
                  {
                    name: "CSS",
                    image: require("../../../Assets/Icons/css.svg").default,
                  },
                  {
                    name: "GIT",
                    image: require("../../../Assets/Icons/git.svg").default,
                  },
                ]}
                showName
              />
            </div>
            <div className="content-half">
              <h3>{t("home.testing.title")}</h3>
              <p>{t("home.testing.description")}</p>
              <ImageGrid
                images={[
                  {
                    image: require("../../../Assets/Icons/cypress.svg").default,
                    name: "Cypress.io",
                  },
                  {
                    image: require("../../../Assets/Icons/playwright.svg")
                      .default,
                    name: "Playwright",
                  },
                  {
                    image: require("../../../Assets/Icons/postman.svg").default,
                    name: "Postman",
                  },
                ]}
                showName
                columns={3}
              />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
