import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";

interface Props {
  open: boolean;
  onClose: () => void;
  message: string;
}

export default function Snackbar({ open, onClose, message }: Props) {
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        onClose();
      }, 2500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div className={["snackbar", open ? "open" : undefined].join(" ")}>
      <FontAwesomeIcon className="success" icon={solid("check-circle")} />
      <span>{message}</span>
      <FontAwesomeIcon
        className="close"
        onClick={() => onClose()}
        icon={solid("remove")}
      />
    </div>
  );
}
