import React from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";

interface Props {
  isBannerShown?: boolean;
}

export default function Navbar({ isBannerShown }: Props) {
  const { t } = useTranslation();

  const navLinkHandler = ({ isActive }: { isActive: boolean }) =>
    isActive ? "active" : undefined;

  return (
    <div className="container">
      <nav>
        <Link to="/">
          <img
            className={!isBannerShown ? "logo no-banner" : "logo"}
            src={require("../../../Assets/logo-v2.png")}
            alt="Tecspert logo"
          />
        </Link>
        <div className="nav-items">
          <NavLink className={navLinkHandler} to="/">
            {t("menu.home")}
          </NavLink>
          <NavLink className={navLinkHandler} to="/about">
            {t("menu.about")}
          </NavLink>
          <NavLink className={navLinkHandler} to="/work">
            {t("menu.work")}
          </NavLink>
        </div>
      </nav>
    </div>
  );
}
