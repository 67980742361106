import React from "react";

type Image = {
  name: string;
  image: NodeRequire;
};

interface Props {
  images: Image[];
  showName: boolean;
  columns?: 3 | 4;
}

export default function ImageGrid({ images, showName, columns = 4 }: Props) {
  return (
    <div className={columns === 3 ? "skillContainer three" : "skillContainer"}>
      {images.map(({ name, image }) => {
        return (
          <div className="skill grow" key={name}>
            <img src={image as any} alt={name} />
            {showName && <span className="show-hover">{name}</span>}
          </div>
        );
      })}
    </div>
  );
}
