import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Footer from "../../Shared/Footer";
import Header from "../../Shared/Header";

export default function About() {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <section>
        <div className="container">
          <div className="content-wrapper left">
            <div className="content">
              <h2>{t("about.background.title")}</h2>
              <p>{t("about.background.description.0")}</p>
              <p>{t("about.background.description.1")}</p>
              <p>{t("about.background.description.2")}</p>
              <p>{t("about.background.description.3")}</p>
              <p>
                {t("about.background.description.4")}
                <br />
                <Link className="link-text" to="/work">
                  {t("about.background.work_experience")}
                </Link>
              </p>
            </div>
          </div>
          <div className="content-wrapper">
            <div className="content-img">
              <img
                src={require("../../../Assets/nature.png")}
                alt="Nature park Sallandse Heuvelrug"
              />
            </div>
            <div className="content">
              <h2>{t("about.hobbies.title")}</h2>
              <p>{t("about.hobbies.description.0")}</p>
              <p>{t("about.hobbies.description.1")}</p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
