import React from "react";
import { useTranslation } from "react-i18next";
import Navbar from "../Navbar";

interface Props {
  children?: React.ReactNode;
  isBannerShown?: boolean;
}

export default function Header({ isBannerShown, children }: Props) {
  const { i18n } = useTranslation();
  const setLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };
  const isLanguageActive = (lang: string) =>
    i18n.language === lang ? "active" : undefined;

  return (
    <header>
      <div className="language-selector">
        <button
          className={isLanguageActive("en")}
          onClick={() => setLanguage("en")}
        >
          EN
        </button>
        <span>|</span>
        <button
          className={isLanguageActive("nl")}
          onClick={() => setLanguage("nl")}
        >
          NL
        </button>
      </div>
      <Navbar isBannerShown={isBannerShown} />
      {children}
    </header>
  );
}
